import React from 'react'
import "./styles.css"
import GridItemsComponent from '../GridItemsComponent'
import ContactForm from '../Forms/ContactForm/index'
import { industriesLandingPage } from '../../content/homepage/homeContent'
import ThIndustriiHomeSlider from "../../markup/element/th-sliders/ThIndustriiHomeSlider"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function LandingPage() {
    return (
        <div class="content-inner landing-page-wrapper">
            <Introduction />
            {/* Industries */}
            <Industries />
            <Services />
            {/* Contact Form */}
            <div class="landing-page__section4">
                <ContactForm />
            </div>
            {/* <Mentions /> */}
        </div>
    )
}

export default LandingPage


const Introduction = () => {
    const img = require("../../assets/landing-page/shutterstock_1582400806.png")
        return (
        <div class="landing-page__section1">
            <div class="landing-page__section1__item1">
                <h2>Linda contribuie la dezvoltarea IMM-urilor prin fonduri de digitalizare</h2>
                {/* 3 horizontally aligned bullet points */}
                <p>Suntem aici sa va ajutam, dezvoltand solutia tehnica pentru digitalizarea companiei
                    dumneavoastra.</p>
                <p>Digitalizarea este esentiala pentru business-ul dumneavoastra, contribuind la
                    dezvoltarea in ritm alert a acestuia, pentru a combate toate provocarile mediului de
                    afaceri curent.</p>
                <p>Digitalizarea este cheia catre beneficii clare atunci cand este implementata unde trebuie.
                    Cand un proces functioneaza intr-o maniera dezechilibrata ce nu poate fi sustinuta pe
                    termen lung impiedica organizatia din a-si telurile din punct de vedere strategic, atunci
                    este nevoie de digitalizare. Suntem alaturi de dumneavoastra in procesul de digitalizare.</p>
            </div>
            <img src={img.default} alt="Linda - contribuiri dezvoltare IMM-uri" />
        </div>
    )
}

const Industries = () => (
    <div className="section-full bg-white content-inner">
        <div className="container">
            <h2
                style={{
                    textAlign: "center",
                    color: "#000000",
                    fontWeight: 900,
                }}
            >
                Industrii
            </h2>
            <ThIndustriiHomeSlider industries={industriesLandingPage} nrSlidestoShow={4} />
        </div>
    </div>
)

const Services = () => {
    return (
        <div className="section-full bg-white content-inner">
            <div className="container">
                <h2
                    style={{
                        textAlign: "center",
                        color: "#000000",
                        fontWeight: 900,
                    }}
                >
                    Servicii
                </h2>
                <GridItemsComponent />
            </div>
        </div>
    )
}


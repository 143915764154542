import React from 'react'
import './styles.css'

function GridItemsComponent() {
    const data = [
        {
            title: 'Implementare agricultura inteligenta',
            isItemsContainer: false,
            content: ["Monitorizarea culturilor", "Monitorizare resurse de apa, electrovalva", "Automatizari, optimizari si control sisteme de irigatii", "Sisteme de monitorizare, control si optimizare zoocultura", "Implementare sisteme automate de monitorizare si control pentru Morarit si panificatie", "Monitorizare temperaturi, umiditate pentru silozuri și spatii de depozitare cereale", "Stalpi pentru agricultura", "Capcane daunatori si rozatoare"]
        },
        {
            isItemsContainer: true,
            items: [
                {
                    title: 'Digitalizare pentru sisteme de productie existente',
                    content: [
                        "Implementare sau modernizare sisteme ERP cu aducerea datelor suplimentare din fluxuritehnologice (informatii despre starea utilajelor, a resurselor de materiale utilizate, aresurselor energetice consumate, a resurselor umane folosite);",
                        "Modernizare fluxuri tehnologice cu utilaje mai eficiente, senzori, roboti pentru cresterea productivitatii si calitatii;",
                        "Implementare solutii de diagnoza predictiva pentru reducerea timpilor morti, planificarea activitatilor de mentenanta la cost minim;",
                        "Proiectare si dezvoltare de aplicatii personalizate pentru integrarea informatiilor tehnologicecu scop de monitorizare si analiza a intregii activitati productive.",
                        "Stalpi pentru agricultura (statii meteo)",
                    ]
                },
                
            ]
        },
        {
            title: 'Energie verde',
            isItemsContainer: false,
            content: [
                "Proiectare, dezvoltare si implementare solutii de productie energetica pentru acoperireaconsumului propriu sau injectarea in retea;",
                "Integrarea cu sisteme conexe (cladiri inteligente, fluxuri de productie, statii de incarcare EV);"
            ]
        },
        {
            title: 'Solutii smart city',
            isItemsContainer: false,
            content: [
                "Statii de incarcare vehicule electrice",
                "Parcari inteligente (monitorizare locuri de parcare libere);",
                "Monitorizare traffic auto, pietonal"
            ],
        },
        {
            title: 'Consultanta, elaborare proiect',
            isItemsContainer: false,
            content: ["Elaborare plan de afaceri, macheta financiara", "Proiect tehnic conform planului de afaceri", "Bugetare echipamente si solutii tehnice"]
        },
        {
            title: 'Implementare sistem management energetic',
            isItemsContainer: false,
            content: ["Optimizarea consumului energetic in fluxuri tehnologice", "Managementul instalatiilor de productie a energiei", "Gestionarea echipentelor de productie a energiei electrice verzi/regenerabile"]
        },
    ]
    return (
        <div class="th-grid-items-container">
            {data.map((el, key) => (
                <div key={key} class="th-grid-item__wrapper">

                    {/* if container has multiple items */}
                    {el?.isItemsContainer !== undefined && el?.isItemsContainer ? el.items.map((item, key) => (
                        <div key={key} class="th-grid-item">
                            <h4>{item.title}</h4>
                            {
                                item.content !== undefined ? <ul class="landing-page__list_type1">{item.content.map((contentItem, key) => (
                                    <li key={key}><p>{contentItem}</p></li>
                                ))}</ul> : null
                            }
                        </div>
                    )) :
                        <div class="th-grid-item">
                            <h4>{el.title}</h4>
                            {/* If content exists, show it */}
                            {el.content !== undefined ? <ul class="landing-page__list_type1">{el.content.map((contentItem, key) => (
                                <li key={key}><p>{contentItem}</p></li>
                            ))}</ul> : null}
                        </div>}

                </div>
            ))}
        </div>
    )
}

export default GridItemsComponent
import React from 'react'
import Layout from "../markup/layout/layout"
import { PageBanner } from "../markup/layout/PageBanner"
import Seo from "../markup/layout/seo"
import LandingPage from '../components/Landing-Page'

function program_digitalizare() {
  const pageTitle = "Program Digitalizare"

  return (
    <Layout>
    <Seo title={pageTitle} />
    <PageBanner
      pageTitle={pageTitle}
      isHomePage={false}
      pageInfo={{
        title: pageTitle,
        parent: { title: "", slug: "" },
        hasParent: false,
      }}
    />
    <LandingPage />
  </Layout>
  )
}

export default program_digitalizare

